import loginPageIconImport from "../assets/login.svg";
import registerPageIconImport from "../assets/register.svg";

export const adminPath = "pendidi-adminn";
export const teachersubadminPath = "pendidi-teacher-di";
export const domainName = "pendidi.com";
export const isManualPayment = false;
export const isCouponable = false;
export const isMultiYear = true;
export const isCodes = true;
export const isBtns = false;
export const isLoginTokens = true;
export const profileStatistics = true;

export const enableAutoLogin = false;

export const isFreeTrialAvailable = false;

export const adminPanelColor = "SlateBlue";
export const headerSectionColor = "didiBlue";
export const navbarIconColor = "text-didiBlue";
export const navbarDisclosureColor = "sky";

export const progressBarColor = "sky";
export const loadingBarColor = "sky";

export const isBunny = true;

export const isPlatformSubscription = false;
export const isTimedManualSubscription = false;

export const isPrepaidSystem = false;
export const prepaidInfoColor = "blue";

export const autoChangeRandomNames = false;

export const navThemeRTL = true;
export const isCoursesRenamed = false;
export const registerPageIcon = registerPageIconImport;
export const loginPageIcon = loginPageIconImport;
export const loginPageColor = "didiRed";
export const registerPageColor = "SlateBlue";

export const isAnalytics = false;

export const isMultiAdmin = true;

export const isSubjectSubscriptionable = true;

export const isAccountCreationRequest = false;

export const showExamStatisticsTable = false;

export const isAdminToAdminNote = false;
export const isAdminAbleToChangeUsersYear = false;

export const isOtpEnabled = false;

export const isWalletEnabled = false;

export const isStudyPlannerEnabled = false;

export const isOnlySubscribeFromWallet = false;

export const isCenterUsers = false;

export const isForgetPassword = true;

export const isRegisterByDepartments = true;

export const isAdminUsers = false;

export const isSubscribeBySubjectTeacher = false;

export const isMigrationFromInsertAuto = false;
export const isChargeInsertAuto = false;
export const isInsertAutoTypes = false;

export const isCourseRenamedToMonthlySubscriptions = false;

export const isCommunity = false;

export const isCustomUserExamLimit = false;
export const isCustomUserVideoLimit = false;

export const isCenterApi = false;

export const isLoginAsUser = false;

export const isEssayQuestions = false;

export const isAdminCourseStatistics = false;

export const isSearch = false;

export const isPinnedCourse = false;

export const isUnassignedSubscription = false;

export const isStoreLocatorAvailable = false;

export const isYoutubeVideoButton = false;

export const isAddCenterUser = false;
